import { AxiosResponse } from 'axios';

import { axiosIntance } from '../../../config/AxiosConfig';

import { ResponseUsersProps, User } from 'src/app/feature/Users/models/User';

export const UserDao = {
  SignIn: (form) => axiosIntance.post(`/auth/login`, form),
  list: (
    page: number | string | null,
    limit: number | string | null,
    search: string | null
  ): Promise<AxiosResponse<ResponseUsersProps>> => {
    let query = '';
    if (search) {
      query = `&filter=${search}`;
    }

    if (!page) {
      page = process.env.REACT_APP_LIST_PAGE_DEFAULT;
    }
    if (!limit) {
      limit = process.env.REACT_APP_LIST_ROW_DEFAULT;
    }
    return axiosIntance.get(`/users?page=${page}&limit=${limit}${query}`);
  },
  show: (id: string): Promise<AxiosResponse<User>> => {
    return axiosIntance.get(`/users/${id}`);
  }
};
